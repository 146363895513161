/*
Template Name: Shreyu - Responsive Bootstrap 4 Admin Dashboard
Author: CoderThemes
Version: 1.0.0
Website: https://coderthemes.com/
Contact: support@coderthemes.com
File: Main Css File
*/

/* =======================
    TABLE OF CSS
    1. Fonts 
    2. General 
    3. Left Menu 
    4. Topbar 
    5. Page Head 
    6. Footer 
    7. Horizontal Nav 
    8. Right Sidebar 
    9. Layouts 
    10. Helper 
    11. Social 
    12. Widgets 
    13. Custom Checkbox 
    14. Custom Radio 
    15. Print 
    16. Preloader 
    17. Plugins 
    18. Components Demo 
    19. Tasks 
    20. Email 
    21. Timeline 
    22. Extra Pages 
    23. Authentication 
   ======================= */



//Fonts
/* 1. Fonts */
@import "custom/fonts/fonts";

//Core files
@import "./node_modules/bootstrap/scss/functions";
@import "./node_modules/bootstrap/scss/variables";
@import "variables";
@import "./node_modules/bootstrap/scss/mixins.scss";

// Structure
/* 2. General */
@import "custom/structure/general";
/* 3. Left Menu */
@import "custom/structure/left-menu";
/* 4. Topbar */
@import "custom/structure/topbar";
/* 5. Page Head */
@import "custom/structure/page-head";
/* 6. Footer */
@import "custom/structure/footer";
/* 7. Horizontal Nav */
@import "custom/structure/horizontal-nav";
/* 8. Right Sidebar */
@import "custom/structure/right-sidebar";
/* 9. Layouts */
@import "custom/structure/layouts";

//Components
/* 10. Helper */
@import "custom/components/helper";
/* 11. Social */
@import "custom/components/social";
/* 12. Widgets */
@import "custom/components/widgets";
/* 13. Custom Checkbox */
@import "custom/components/custom-checkbox";
/* 14. Custom Radio */
@import "custom/components/custom-radio";
/* 15. Print */
@import "custom/components/print";
/* 16. Preloader */
@import "custom/components/preloader";

// Plugins
/* 17. Plugins */
@import "custom/plugins/slimscroll";
@import "custom/plugins/apexcharts";
@import "custom/plugins/calendar";
@import "custom/plugins/react-select";
@import "custom/plugins/multiple-select";
@import "custom/plugins/flatpickr";
@import "custom/plugins/bootstrap-touchspin";
@import "custom/plugins/parsley";
@import "custom/plugins/form-wizard";
@import "custom/plugins/editor";
@import "custom/plugins/dropzone";
@import "custom/plugins/datatable";
@import "custom/plugins/table-list-js";
@import "custom/plugins/kanban";
@import "custom/plugins/metismenu";

//Pages
/* 18. Components Demo */
@import "custom/pages/components-demo";
/* 19. Tasks */
@import "custom/pages/tasks";
/* 20. Email */
@import "custom/pages/email";
/* 21. Timeline */
@import "custom/pages/timeline";
/* 22. Extra Pages */
@import "custom/pages/extra-pages";
/* 23. Authentication */
@import "custom/pages/authentication";

.navbar-custom .button-menu-mobile {
width:0;
}
html,body.accept_terms
{
  overflow-x:hidden!important;   
}
.left-side-menu-condensed .content-page
{margin-left:0px!important;}
.terms_scroll::-webkit-scrollbar 
{
  width: 20px;
}
.terms_scroll::-webkit-scrollbar-track {
  border: 1px solid grey; 
  border-radius: 10px;
}
.terms_scroll::-webkit-scrollbar-thumb {
  background: #006239; 
  border-radius: 10px;
}
.terms_scroll::-webkit-scrollbar-thumb:hover {
  background: #006239; 
}

.scroll_terms{width:900px;height:500px;}
body.citizens{
overflow-x:hidden!important;
}

body.citizens{
padding-left:0px!important;
padding-right:0px!important;
background-color: #f3f4f7;
}
body.accept_terms
{
padding-left:0px!important;
padding-right:0px!important;
}
.custom-control-input:disabled ~ .custom-control-label
{
   color:#000;
}
.swiper-button-prev:after,.swiper-button-next:after{color:#fff!important;}
.swiper-button-prev:after
{
 padding-left:40px;
}

.terms-logo-hide-report
{
padding-top:50px;}
.terms-logo-hide
{
padding-top:30px;}

body.status_of_report_body .content-page
{
padding:0px!important;
}
body.report
{
     background:#fff;
}
body.citizens .content-page
{
padding:0px!important;
}
body.citizens .container-fluid
{
padding-left:0px!important;
padding-right:0px!important;
}

body.accept_terms .content-page
{
padding:0px!important;
}
body.accept_terms
{
padding-bottom:0px!important;
}


.f_17
{
     font-size:17px;
}
.submit_report
 {
 border-radius:10px;
 background:#fff;
 height:400px;
 }
.fillreport .footer{display:none!important;}
.green_bg_bg
{
     background:#006239;
     border:none;
	 width:120px;
}
a
{
     color:#006239;
}
.bg-clr
{
     background:#291424;
	 width:120px;
}
.p_5
{
padding-top:5px;
padding-left:5px;
}
.text-r
{
     text-align:right;
}
.f-15
{font-size:15px!important;}
.bg_pop{background:#0b1913;}
.m-50
{
   padding-top:80px;
}
.m-40
{
   padding-top:50px;
}
.gr_ey
{
     color:#2d2d2d;
}
.card-style
{
     background:#006239!important;
	 border-radius:10px!important;
	 padding-bottom:15px!important;
}
.radio_custom_sirp .custom-control-label
    {
    margin-bottom: 5px !important;
    margin-left: 0px !important;
    padding-top: 8px !important;
    padding-left: 15px !important;
	}		
.radio_custom_sirp .custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
    background-image:url("../images/check.png")!important;
}
.radio_custom_sirp .custom-control-input:checked ~ .custom-control-label::before
{
     border:1px solid #14653f;
	 background:transparent;
	 color:#000!important;
}
.radio_custom_sirp .custom-control-label::after {
    position: absolute;
    top: 0.15625rem;
    left: -1.5rem;
    display: block;
    width: 2rem;
    height: 2rem;
    content: "";
    background: no-repeat 50% / 50% 50%;
}
.custom-checkbox .custom-control-label::before {
    border-radius: 1.3rem;
	width: 30px;
    height: 30px;
	border:2px solid #666666;
}
.btn_terms
{
     margin-bottom:80px!important;
}
.dont_accept_btn
{
     background:#291424;
	 width:200px;
	 padding-top:20px;
	 padding-bottom:20px;
	 padding-left:20px;
	 padding-right:20px;
	 border:none;
	 margin-bottom:40px;
}
.accept_btn
{
     background:#006239;
	 padding-top:20px;
	 padding-bottom:20px;
	 padding-left:20px;
	 padding-right:20px;
	 border:none;
	 width:200px;
	 margin-bottom:40px;
}
.term_bg
{
     background:#efefef;
	 margin-left:50px;
	 margin-right:50px;
	 border-radius:10px;
	 height:500px;
	 
}

.content-page .terms_bg
{padding:0px!important;}

.container-fluid .terms_bg
{padding-left:0px!important;
padding-right:0px!important;}

.col-lg-3 .terms_bg
{padding-left:0px!important;
padding-right:0px!important;}

#wrapper .terms_bg
{
     background:#ededee!important;
}
.btn_position
{
    position: absolute;
    top: -80px;
    left: 0px;
    right: 23px;
}
.status-report textarea
{height:200px!important;}
.card_bg
{
     background:#f5f5f5;
	 border-radius:10px;
	 border-bottom:1px solid #c8c8c8;}
.card_bg_2
{
     background:#f5f5f5;
	 border-radius:10px;
	 border:1px solid #c8c8c8;
}
.status_of_report .table th
 {
    border-top: none;
 }
 .status_of_report .table thead th
 {
    border-bottom: 1px solid #c8c8c8;
 }
.status_of_report .table th {
     
    font-weight: 700;
    color: #4a4a4a;
    font-size: 18px;
}
.status_of_report .table td {
     
    color: #4a4a4a;
    font-size: 17px;
}
.status_bg
{
     background:#f5f5f5;
}
.p_5_0
{
     padding-top:50px!important;
}
.b_10
{
     border:1px solid #c0c0c0;
	 border-radius:10px;
}
.f_700
{
     font-weight:900!important;
}
.btn_modal
{
     padding-left:80px!important;
     padding-right:80px!important;
     padding-top:15px!important;
     padding-bottom:15px!important;
}
.modal-text
{
     color:#5a5a5a;
}
.mob-float-right
{
     float:right;
}
.footer{
    background:#006239;
	color:#fff;
	left:0;
	position:relative; 
}
.row_height{
     height:500px;
}
.security_news{
     padding-left:50px;
	 padding-right:50px;
}
.f-18{
    font-size:18px;
}

.bl_og{
    padding-left:40px;
	padding-right:40px;
}
.blog-text{
    font-size:20px;
	font-weight:700;
	text-align:left!important;
	padding-left:40px;
	padding-right:40px;
}
.content-page {
    margin-left: 0px;
}
.menu-color{
    color: #007d53;
	font-size:15px;
	font-weight:600;
}
.navbar-custom{
    height: 100px;
	border-bottom:1px solid #0f613d;
}


.p-30{
    padding-left:30px;
	padding-right:30px;
}
.m-30{
	padding-top: 12px!important;
    padding-bottom: 12px!important;
}
.violet{ background:#151146; }
.grey
{color:#535353;}
.g_menu
{color:#007d53;}
.f-16
{
     font-size:16px;
}
.f-19
{
     font-size:19px;
}
.f-600
{font-weight:600!important;}
.f-800
{font-weight:800!important;}
.border-b
{border-bottom:1px solid #006239}
.white_bg
{background:#fff;
border:none;}


.p-50 {
    padding-top: 60px;
    padding-bottom: 40px!important;
}
.m-80
{
   margin-left:100px;
   margin-right:100px;
}
.card
{
   border-radius: 1.25rem;
}
.g_border
{
   border:1px solid #006239;
}
.login-sirp .form-control
{
  border-radius:0px;
  border-bottom:1px solid #c5c2cf;
  border-top:none;
  border-right:none;
  border-left:none;
  text-align:center;
  font-size: 19px;
  color: #c5c2cf;
}
.white
{
     color:#fff;
}
.green
{
     color:#006239;
}
.black
{
     color:#000;
}
.light_green
{
     background:#f4fff2;
}
.green_bg
{
     background:#006239;
	 border:none;
}
.btn-primary {
    color: #fff;
    background-color: #006239;
    border-color: #006239;
}
.btn-primary:hover {
    color: #006239;
    background-color: #fff;
    border-color: #006239;
}

.p-100
{
     padding-top:150px;
	 padding-bottom:150px;
}
@media only screen and (min-width: 300px) and (max-width: 800px)
{   
.logo-lg
{display:none;}
.logo-sm
{display:block!important;}
     .scroll_terms{width:100%;}  
.mob-logo-lg{width:100px!important;height:50px;}
       .mob_foot{font-size:10px;}
       .logo-mob
	   {
	       text-align:center!important;
	   }
       .mob-reporter-padding
	   {
	      padding-left:22px!important;
	   }
      .text-r
		{
			 text-align:left;
		}
			  .mob-hide
	  {
	     display:none;
	  }
	  .navbar-custom .logo 
	  {
          display: block!important;
      }
	  .mob-float-right
		{
			 float:none!important;
		}
	 .bl_og
		{
			 padding-left:0px;
			 padding-right:0px;
		}
		.blog-text
		{
			 font-size:14px;
			 font-weight:700;
			 text-align:left!important;
			 padding-left:0px;
			 padding-right:0px;
		}
}

@media only screen and (min-width: 1000px) and (max-width: 1024px){
     /*.navbar-custom .button-menu-mobile .menu-icon {
    display: none;}
	.logo-lg{display:inline-block!important;
	}*/
	.navbar-custom .logo
	{display:block!important;}
}


#sidebar-menu > ul > li.mm-active > a
{
     border:none;
}
.left-scroller{
     .scrollbar-container.ps 
	{ 
          overflow: hidden!important;
	}

      }
.ps--active-y{
     &.scrollbar-container.ps 
	{ 
          overflow: hidden!important;
	}
}
.p-svg
{
     transform: rotate(269deg) scale(1.04)!important;
	 padding-left:5px;
}
.p-svg-down
{
     margin-top:-8px;
     transform: rotate(90deg) scale(1.04)!important;
	 padding-left:5px;
}
#sidebar-menu > ul > li > a svg
{
     text-align:center!important;
}
.modal
{
 z-index:9999;
}
body {
    padding-bottom: 0px!important;
  
	}
	.scrollbar-container.ps 
	{
    overflow: initial!important;
	}
	#sidebar-menu > ul > li.mm-active
	{position:relative;}
	
#sidebar-menu > ul > li.mm-active:before{
    background-image:url("../images/new.png")!important;
	content: "";
    width: 30px;
    height: 30px;
    background-size: 100%;
    margin-right: 0.75rem;
    position: absolute;
    top: -18px;
    right: -21px;
	z-index:9999;
}
#sidebar-menu > ul > li.mm-active:after{
    background-image:url("../images/new.png")!important;
    content: "";
    width: 30px!important;
    height: 30px;
    background-size: 100%;
    margin-right: 0.75rem;
    position: absolute!important;
    top: 52px!important;
    right: -24px!important;
    z-index: 9999;
    transform: rotate(273deg) scale(1.04)!important;
}

.content-page 
    {
	margin-left: 183px!important;
    position: relative!important;
    left: -10px!important;
	
	}
body
{
     background:#f4fff2;
}
.left-side-menu
   {
    width: 198px;
	z-index:0!important;
	top:0!important;
	background:#f4fff2;
	}
.btn_top
{
    color: #000!important;
    background-color: #fff!important;
    border-radius: 50px!important;
    border: 1px solid #cec9c9!important;
}
.light_green
{
    background:#f4fff2;
	padding-left: 20px;
    padding-right: 20px;
    padding-top: 20px;
    padding-bottom: 20px;
    margin-bottom: 20px;
}

.content-page
{
         margin-top: 0px;
}

#sidebar-menu > ul > li.mm-active > a
{
    padding-right: 1.25rem!important;
	height: 60px;
    border-radius: 10px;
    /*display: flex;*/
    align-items: center;
    margin-bottom: .25rem;
    padding-left: 1.25rem;
    position: relative;
    --tw-text-opacity: 1;
    color: #000;
}

.page-title h4 {
    font-size: 15px;
}
.content-page 
{
    margin-left: 200px;
}
#sidebar-menu > ul > li > a
{
     padding: 12px 4px;
	 text-align:center;
}
.form-control-file
{
    border: 1px solid #e2e7f1;
    border-radius: 0.3rem;
    padding-top: 3px;
    padding-bottom: 3px;
    padding-left: 3px;
}
.bg_grey
{background:#fbfbfb;}
.bg_view
{background:#673232;}
.l_g
{
   color:#5fa270;
}
.l-green
{
   background:#b1f8b6;
}
.l_b
{
   color:#95e3f0;
}
.l-blue
{
   background:#daf7fe;
}
.l_r
{
   color:#fd7691;
}
.l-red
{
   background:#ffcbcb;
}
.drop-down-menu[x-placement^=bottom]:before {
  content: "";
  position: absolute;
  border-color: rgba(194, 225, 245, 0);
  border: solid transparent;
  border-bottom-color: white;
  border-width: 11px;
  margin-left: -10px;
  top: -21px;
  right: 65px;
  z-index: 1;
} 

.drop-down-menu[x-placement^=bottom]:after {
    content: "";
    position: absolute;
    right: 66px;
    top: -21px;
    width: 0;
    height: 0;
    border: solid transparent;
    border-width: 10px;
    border-bottom-color: #2B1A41;
    z-index: 0;
}

.drop-down-menu[x-placement^=top]:before {
     content: "";
     position: absolute;
     border-color: rgba(194, 225, 245, 0);
     border: solid transparent;
     border-top-color: white;
     border-width: 11px;
     margin-left: -10px;
     bottom: -21px;
     right: 65px;
     z-index: 1;
   } 
   
   .drop-down-menu[x-placement^=top]:after {
       content: "";
       position: absolute;
       right: 66px;
       bottom: -21px;
       width: 0;
       height: 0;
       border: solid transparent;
       border-width: 10px;
       border-top-color: #2B1A41;
       z-index: 0;
   }
.f-40{font-size:40px;}
.page-title .breadcrumb{display:none;}
.edit-bg
{
  background:#954dbe;
  border:1px solid #954dbe;
}
.delete-bg
{
  background:#be4d6c;
  border:1px solid #be4d6c;
}
.nav-pills .nav-link.active
{
    color: #fff;
    background-color: #006239;
}
.pro_gress .bg-warning
{
     background-color:#fa2829!important; 
}
.pro_gress .bg-success
{
     background-color:#fc79e3!important;  
}
.pro_gress .bg-info
{
     background-color:#4d74f5!important;  
}

	
/*.table td {font-size:11px;
	 padding-left: 0rem;
	 padding-right: 0rem;
	 padding-top: 10px;
	 padding-bottom: 10px;
	 }
.table thead th
{
     font-size:11px;
	 padding: 0rem;
}*/

#wrapper
{
     background:#f4fff2;
}
#sidebar-menu > ul > li.mm-active > a
{
     background:#f4fff2;
}
#sidebar-menu > ul > li > a:hover
{
     background:#f4fff2;
}
#sidebar-menu > ul > li > a 
{
     color:#fff;
}
#sidebar-menu > ul > li > a svg
{
color:#fff;
display:block;
width:90%;
}
#sidebar-menu > ul > li.mm-active > a svg
{
color:#000!important;
display:block!important;
}

#sidebar-menu > ul > li {
color:#fff!important;}

.green_bg_dark
{
     background:#072308;
}
.lo-go .avatar-sm
 {
    height: 4.25rem;
    width: 4.25rem;
}
.p-30
{
     padding-left:70px;
	 padding-right:70px;
}
.m-30
{
	padding-top: 12px!important;
    padding-bottom: 12px!important;
}
.violet
{
background:#151146;}
.grey
{color:#535353;}
.g_menu
{color:#007d53;}
.f-16
{
     font-size:16px;
}
.f-19
{
     font-size:19px;
}
.f-600
{font-weight:600;}
.f-800
{font-weight:800;}
.border-b
{border-bottom:1px solid #006239}
.white_bg
{background:#fff;}

.p-50{
padding-top:30px;
padding-bottom:30px;}
.m-80
{
   margin-left:100px;
   margin-right:100px;
}
.card
{
   border-radius: 1.25rem;
}
.g_border
{
   border:1px solid #006239;
}
.login-sirp .form-control
{
  border-radius:0px;
  border-bottom:1px solid #c5c2cf;
  border-top:none;
  border-right:none;
  border-left:none;
  text-align:center;
  font-size: 19px;
  color: #c5c2cf;
}
.white
{
     color:#fff;
}
.green
{
     color:#006239;
}
.black
{
     color:#000;
}

.green_bg
{
     background:#006239;
}
.btn-primary {
    color: #fff;
    background-color: #006239;
    border-color: #006239;
}
.btn-primary:hover {
    color: #006239;
    background-color: #fff;
    border-color: #006239;
}

.p-100
{
     padding-top:150px;
	 padding-bottom:150px;
}
.round
{
   background:#fa2829;
   border-radius: 50%;
    padding-left: 7px;
    font-size: 14px;
    padding-right: 7px;
    padding-top: 0px;
    padding-bottom: 0px;
}
.round-2
{
   background:#fc79e3;
   border-radius: 50%;
    padding-left: 7px;
    font-size: 14px;
    padding-right: 7px;
    padding-top: 0px;
    padding-bottom: 0px;
}.round-3
{
   background:#4d74f5;
   border-radius: 50%;
    padding-left: 7px;
    font-size: 14px;
    padding-right: 7px;
    padding-top: 0px;
    padding-bottom: 0px;
}

.login-page-padd{
     padding-top:160px;
	 padding-bottom:78px;
}

.p-19{
	padding-top:19px;
	padding-bottom:19px;
}

.height-chart{
	height:100%;
	.card-body{
		height:100%;
	}
}

.chart-filter-options{
	display: flex;
	align-items:center;
	justify-content:flex-end;
	.sort-by{
		margin-right:5px;		
	}
	.react-select{
		min-width:150px;
	}
	&.absolute-filter{
		position: absolute;
		right: 20px;
		z-index: 1000;
	}
}

.black-axis-font{
	.apexcharts-yaxis text, .apexcharts-xaxis text{
		fill: #000000;
		font-size:13px;
		font-weight:700;
	}
	.apexcharts-legend-text{
		font-size:13px !important;
		font-weight:700 !important;
		color:#000 !important;
		margin-right: 5px;
	}
	.apexcharts-legend-marker{
		height: 16px !important;
		width: 16px !important;
		margin-right: 6px;
	}
	
	.apexcharts-legend.apexcharts-align-left .apexcharts-legend-series{
		display: flex;
	}
	
	.apexcharts-text tspan{
		text-transform: uppercase;
	}
	&.small-font-xaxis{
		.apexcharts-yaxis text{
			font-size:12px;
		}
		.apexcharts-xaxis text{
			font-size:10px;
		}
	}
}

.full-height-chat{
	height:auto;
	&.conversation-list .ctext-wrap{
		background-color: #ffffff;
		color: #000000;
		&:after{
			border-right-color: #ffffff;
			border-top-color: #ffffff;
		}		
	}
	
	&.conversation-list .odd .ctext-wrap{		
		background: #dcf8c6;
		color: #000000;
		&:after{
			border-top-color: #dcf8c6;
			border-left-color: #dcf8c6;
			border-right-color: transparent;
		}
	}
	
	&.conversation-list .chat-avatar{
		width: 40px;
	}
}

.chat-send{
	background-color: #006239;
	border-color: #006239;
}

.whats-like-chat{
	background: #e5ddd5;
    padding: 15px 10px;
    border-radius: 5px;
}

.chat-use-item{
	padding:7px 10px;
	cursor:pointer;
	&.chat-active{
		background:#f3f4f7;
		border-radius:7px;
	}
}

.chat-message-wrapper{
	background:#f0f0f0;
	padding-top:7px;
	padding-bottom:7px;
	border-radius:6px;
	.form-group{
		margin-bottom:0px;
	}
	.form-control{
		border-radius:500px;
	}
}

.square-legend-style{
	.apexcharts-legend-marker{
		border-radius:0px !important;
	}
	.apexcharts-legend-text{
		margin-top:-10px;
	}
}

.year-dropdown-style{
	.react-select__option{
		padding:5px 5px!important;
		text-align: center;
	}
}

.year-dropdown-left-style{
	.react-select__option{
		padding:5px 5px!important;
		text-align: left;
	}
}

.fixed-ng-map{
	position:absolute;
	top: 15px;
     left: 6px;
}

.no-shadow{
	box-shadow:none;
}

.right-rounded{
	border-top-right-radius:7px;
	border-bottom-right-radius:7px;
}

.ins-count-head{
	color:#6c5b88;
	font-weight:700;
}

.left-dropdown-arrow.drop-down-menu:before{
	left:65px;
	right:auto;
}
.left-dropdown-arrow.drop-down-menu:after{
	left:56px;
	right:auto;
}

.custom-checkbox.enabled-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before{
	background-color:transparent !important;
}

.content-page{
	.scrollbar-container.ps{
		// overflow: hidden!important;
	}
}

#chat-form{
	width: 100%;
    padding: 0 12px;
}


.conversation-list-pre{
     color: #000000;
     font-size: 12px;
     font-family: "Nunito Sans", sans-serif;
     a{
          color:#2860fa;
     }
 }
 
 
 
 .chat-item-counts{
	color:#fff;
	background:#f00;
	min-width:20px;
	height:20px;
	line-height:20px;
	border-radius:500px;
	display:inline-block !important;
	text-align:center;
	font-size:0.76rem;
	padding-left: 5px;
	padding-right: 5px;
	vertical-align: initial !important;
	font-weight:500;
}

// .apexcharts-marker{
//      r:"10";


// }


.internet_warning{
     top:0px;
     left:0px;
     width:100%

}

@media only screen and (max-width: 768px){
     .content-page {
          margin-left: 0px !important;
      }
     .mapplot-dashboard{
          overflow-x: scroll;
     }
}


