// 
// pagination.scss
//


// Pagination rounded (Custom)
.pagination-rounded {
  .page-link {
    border-radius: 30px !important;
    margin: 0 3px;
    border: none;
  }
}

.page-link{
	color: #072308;
}
.page-item.active .page-link{
	background-color: #072308;
    border-color: #072308;
}